import i18next from 'i18next';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { setLanguage } from 'store/common/utils';
import { abortController } from 'store/utils';

import { getExchangeApi } from './api';


const lang = window.localStorage.getItem('lang');
setLanguage(lang);

export default create(devtools(
  immer((set) => abortController({
    lang: lang || 'en',
    priceMeasurement: window.localStorage.getItem('priceMeasurement') || 'USD',
    squareMeasurement: window.localStorage.getItem('squareMeasurement') || 'ft²',
    exchange: {
      AED: 1,
    },

    setMeasurements: ({ type, val }, { setState }) => {
      setState({ [type]: val });
      window.localStorage.setItem(type, val);

      if (type === 'lang') {
        i18next.changeLanguage(val);
        setLanguage(val);
      }
    },

    getExchange: async (payload, { setState }) => {
      const { data } = await getExchangeApi();

      const AED = data.find(({ to }) => to === 'AED').rate;
      const RUB = data.find(({ to }) => to === 'RUB').rate;
      const USD = data.find(({ to }) => to === 'USD').rate;
      const EUR = data.find(({ to }) => to === 'EUR').rate;
      const GBP = data.find(({ to }) => to === 'GBP').rate;
      const INR = data.find(({ to }) => to === 'INR').rate;
      const TRY = data.find(({ to }) => to === 'TRY').rate;
      const CNY = data.find(({ to }) => to === 'CNY').rate;
      const CAD = data.find(({ to }) => to === 'CAD').rate;
      const AUD = data.find(({ to }) => to === 'AUD').rate;
      const PKR = data.find(({ to }) => to === 'PKR').rate;

      setState({
        exchange: {
          AED,
          RUB,
          USD,
          EUR,
          GBP,
          INR,
          TRY,
          CNY,
          CAD,
          AUD,
          PKR,
        },
      });
    },
  }, set, 'Measurements')),
  {
    name: 'Measurements',
  },
));
